// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudyARZ from '../../../components/CaseStudyARZ'
import SEO from '../../../components/seo'
import messages from '../../../messages/pageTitles.lang'
import dimensionMessages from '../../../messages/dimensions.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './arz.scss'
import baseCaseMessages from '../../../components/CaseStudy/CaseStudy.lang'
import caseMessages from '../../../components/CaseStudy/messages/ARZ/ARZ.lang'

/* eslint-disable max-len */
const heroBgDesktop = (
  <StaticImage src="../../../images/casestudy/herobgdesktop.jpg" alt="" objectFit="cover" className="image" />
)
const heroImgDesktop = (
  <StaticImage src="../../../images/casestudy/arz/arz1.png" alt="" objectFit="contain" className="image" />
)
const welcomeLogo = (
  <StaticImage src="../../../images/casestudy/arz/Logo.png" alt="" objectFit="contain" className="image" />
)
const welcomeImg = (
  <StaticImage src="../../../images/casestudy/arz/arz9.png" alt="" objectFit="contain" className="image" />
)
const func1Img = (
  <StaticImage src="../../../images/casestudy/arz/arz4.png" alt="" objectFit="contain" className="image" />
)
const func2Img = (
  <StaticImage src="../../../images/casestudy/arz/arz5.png" alt="" objectFit="contain" className="image" />
)
const techImg = (
  <StaticImage src="../../../images/casestudy/bedfinder/tech.png" alt="" objectFit="contain" className="image" />
)
const brandingImg = (
  <StaticImage src="../../../images/casestudy/arz/arz9.png" alt="" objectFit="contain" className="image image-brand" />
)
const colorsImg = (
  <StaticImage src="../../../images/casestudy/arz/Colors.png" alt="" objectFit="contain" className="image" />
)
const recognitionImg = (
  <StaticImage src="../../../images/casestudy/arz/recognition.png" alt="" objectFit="contain" className="image" />
)
/* eslint-enable max-len */

import bettyImg from '../../../images/casestudy/arz/arz6.png'

import { projectsList } from '../../../components/CaseStudy/helpers'

type Props = {
  ...StyleProps
}

const data = {
  name: 'ARZ',
  heroTeaser: {
    bg: {
      imageDesktop: heroBgDesktop
    },
    img: heroImgDesktop,
    title: projectsList.arz.title,
    description: projectsList.arz.description
  },
  welcome: {
    logo: welcomeLogo,
    text: caseMessages.welcomeText,
    img: welcomeImg
  },
  stats: [
    {
      number: 1500,
      value: caseMessages.stat1Value,
      color: 'primary',
      icon: 'icon-plane'
    },
    {
      number: 25000,
      positive: true,
      value: caseMessages.stat2Value,
      color: 'tertiary',
      icon: 'icon-user'
    },
    {
      number: 1,
      numberDimension: dimensionMessages.dimensionMillion,
      value: caseMessages.stat3Value,
      color: 'secondary',
      icon: 'icon-house'
    },
    {
      number: 3,
      numberDimension: dimensionMessages.dimensionMonths,
      value: caseMessages.stat4Value,
      color: 'accent',
      icon: 'icon-calendar-heart'
    }
  ],
  betty: {
    title: caseMessages.bettyTitle,
    bettyTextTitle: caseMessages.bettyTextTitle,
    text: caseMessages.bettyText,
    img: bettyImg
  },
  functionalitiesTitle: baseCaseMessages.mainFeaturesTitle,
  functionalities: [
    {
      title: caseMessages.functionality1Title,
      list: [caseMessages.functionality1Item1, caseMessages.functionality1Item2, caseMessages.functionality1Item3],
      img: func1Img
    }
  ],
  rzh: {
    title: caseMessages.rzhTitle,
    bettyTextTitle: caseMessages.rzhTextTitle,
    text: caseMessages.rzhText,
    img: bettyImg
  },
  functionalitiesTwoTitle: baseCaseMessages.mainFeaturesTitle,
  functionalitiesTwo: [
    {
      title: caseMessages.functionality2Title,
      list: [caseMessages.functionality2Item1, caseMessages.functionality2Item2, caseMessages.functionality2Item3],
      img: func2Img,
      imgWide: true
    }
  ],
  roadmapTitle: baseCaseMessages.workflow,
  roadmap: [
    {
      title: baseCaseMessages.roadmapWorkshop,
      timeValue: '',
      timeMeasure: '',
      color: 'primary',
      icon: 'icon-flag'
    },
    {
      title: baseCaseMessages.roadmapConception,
      timeValue: '',
      timeMeasure: '',
      color: 'tertiary',
      icon: 'icon-quality'
    },
    {
      title: baseCaseMessages.roadmapDevelopment,
      timeValue: '',
      timeMeasure: '',
      color: 'secondary',
      icon: 'icon-memory'
    },
    {
      title: baseCaseMessages.roadmapRelease,
      timeLabel: '',
      timeValue: '',
      timeMeasure: '',
      color: 'accent',
      icon: 'icon-heart-filled'
    }
  ],
  brand: {
    title: baseCaseMessages.brandingDoneTitle,
    logo: {
      title: baseCaseMessages.logoTitle,
      list: [caseMessages.brandLogoItem1, caseMessages.brandLogoItem2, caseMessages.brandLogoItem3],
      img: brandingImg
    },
    colors: {
      title: baseCaseMessages.colorsTitle,
      list: [caseMessages.brandColorsItem1, caseMessages.brandColorsItem2, caseMessages.brandColorsItem3],
      img: colorsImg
    },
    recognition: {
      title: baseCaseMessages.brandRecognitionTitle,
      text: caseMessages.brandRecognitionText,
      img: recognitionImg
    },
    tech: {
      title: baseCaseMessages.techTitle,
      list: [
        baseCaseMessages.techPerfomance,
        baseCaseMessages.techScalable,
        baseCaseMessages.techFlexible,
        baseCaseMessages.techArt
      ],
      img: techImg
    },
    design: {
      title: baseCaseMessages.designTitle,
      text: baseCaseMessages.designText,
      list: [baseCaseMessages.designItemInnovative, baseCaseMessages.designItemDevices],
      img: projectsList.arz.overviewImage
    }
  }
}

const CaseArz = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title={formatMessage(messages.TitleCaseStudyArz)}
        description={formatMessage(metaDescription.CaseStudyArzDescription)}
        lang={locale}
        nofollow
      />
      <CaseStudyARZ data={data} styles={styles.case} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseArz)
