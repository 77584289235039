// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { ParallaxProvider } from 'react-scroll-parallax'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../components/basic/Grid'
import ScrollAnimation from 'react-animate-on-scroll'
import CaseTeasers from '../../components/CaseStudy/CaseTeasers'
import CaseHeroTeaser from '../CaseStudy/CaseHeroTeaser'
import CaseWelcome from '../CaseStudy/CaseWelcome'
import CaseLogosSlider from '../CaseStudy/CaseLogosSlider'
import CaseInfo from '../CaseStudy/CaseInfo'
import CaseStats from '../CaseStudy/CaseStats'
import CaseFunctionalities from '../CaseStudy/CaseFunctionalities'
import CaseRoadmap from '../CaseStudy/CaseRoadmap'
import CaseBrand from '../CaseStudy/CaseBrand'
import CaseActionTeaser from '../CaseStudy/CaseActionTeaser'
import CaseTeaser from '../CaseStudy/CaseTeaser'

import baseStyles from '../CaseStudy/CaseStudy.scss'
// import messages from '../CaseStudy/CaseStudy.lang'

type Props = {
  ...StyleProps,
  data: Object
}

const CaseStudy = ({ styles, data }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <ParallaxProvider>
        <CaseHeroTeaser
          title={formatMessage(data.heroTeaser.title)}
          description={formatMessage(data.heroTeaser.description)}
          appstore={data.heroTeaser.appstore}
          titleLink={data.link}
          name={data.name}
          imageDesktop={data.heroTeaser.bg.imageDesktop}
          caseImg={data.heroTeaser.img}
          styles={styles.heroTeaser}
          appstoreImg={data.heroTeaser.appstoreImg}
          googleplayImg={data.heroTeaser.googleplayImg}
        />
        {data.welcome && (
          <CaseWelcome
            logo={data.welcome.logo}
            logoMobile={data.welcome.logoMobile}
            text={data.welcome.text}
            img={data.welcome.img}
            styles={styles.welcome}
          />
        )}
        {data.logosSlider && (
          <CaseLogosSlider title={data.logosSlider.title} list={data.logosSlider.list} styles={styles.logosSlider} />
        )}
        {data.info && (
          <CaseInfo title={data.info.title} list={data.info.list} img={data.info.img} styles={styles.info} />
        )}
        {data.stats && <CaseStats className={styles.stats} stats={data.stats} />}
        {data.betty && (
          <>
            <Container className={styles.container} fluid>
              <h2 className={styles.title}>{formatMessage(data.betty.title)}</h2>
            </Container>
            <CaseTeaser
              title={data.betty.bettyTextTitle}
              richText={data.betty.text}
              img={data.betty.img}
              styles={styles.solution}
              reversed
              imgWide
            />
          </>
        )}
        {data.functionalities && (
          <>
            <Container className={styles.container} fluid>
              <h2 className={styles.title}>{formatMessage(data.functionalitiesTitle)}</h2>
            </Container>
            <CaseFunctionalities
              functionalities={data.functionalities}
              styles={styles.functionalities}
              inversed={data.functionalitiesListInversed}
            />
          </>
        )}
        {data.rzh && (
          <>
            <Container className={styles.container} fluid>
              <h2 className={styles.title}>{formatMessage(data.rzh.title)}</h2>
            </Container>
            <CaseTeaser
              title={data.betty.rzhTextTitle}
              richText={data.rzh.text}
              img={data.rzh.img}
              styles={styles.solution}
              reversed
              imgWide
            />
          </>
        )}
        {data.functionalitiesTwo && (
          <>
            <Container className={styles.container} fluid>
              <h2 className={styles.title}>{formatMessage(data.functionalitiesTwoTitle)}</h2>
            </Container>
            <CaseFunctionalities
              functionalities={data.functionalities}
              styles={styles.functionalities}
              inversed={data.functionalitiesListInversed}
            />
          </>
        )}
        {data.roadmap && <CaseRoadmap roadmap={data.roadmap} title={data.roadmapTitle} />}
        {data.brand && (
          <>
            <Container className={styles.container} fluid>
              <h2 className={styles.title}>{formatMessage(data.brand.title)}</h2>
            </Container>
            <CaseBrand brand={data.brand} styles={styles.brand} />
          </>
        )}
        {data.link && (
          <CaseActionTeaser link={data.link} img={data.check.img} title={data.check.title} text={data.check.text} />
        )}
      </ParallaxProvider>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <CaseTeasers list={['lhh', 'plattenplaner', 'aldiana', 'etravel', 'weekend', 'bedfinder']} allButton />
      </ScrollAnimation>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseStudy)
